import * as React from "react"
import { Button, Popover } from "@mui/material"
import Link from "next/link"
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  DirectionsCar,
} from "@mui/icons-material"
import Router from "next/router"
import ZipcodeForm from "components/ZipcodeForm"
import { useTranslation } from "react-i18next"

const DrivingLessonMenuPopover = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const isOpen = Boolean(anchorEl)
  const { t } = useTranslation()

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const closePopover = (): void => {
    setAnchorEl(null)
  }

  const navigateToLandingPage = (zipcode: string): void => {
    Router.push(`/packages?zipcode=${zipcode}`)
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={openPopover}
        endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        sx={{
          fontWeight: 700,
          height: "min-content",
          padding: "20px",
        }}
        data-cy="driving-lesson-cta"
      >
        <DirectionsCar style={{ marginRight: 10 }} />
        {t("Driving Lessons")}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ padding: 15 }}>
          <ZipcodeForm onSubmitCallback={navigateToLandingPage} />
          <p>
            {t("AlreadyPurchasedQuestion")}
            <br />
            <Link href="/profile/lessons">
              {t("Sign in to book your next lesson")}
            </Link>
          </p>
        </div>
      </Popover>
    </>
  )
}

export default DrivingLessonMenuPopover
