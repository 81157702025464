import React, { useEffect, useState } from "react"
import { Skeleton } from "@mui/material"
import { IMAGES } from "./constants"
import Image from "next/image"

const ImageRandomizer = (): React.ReactElement => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const width = 525
  const height = 525
  const style = {
    borderRadius: "10%",
  }

  useEffect(() => {
    const randomIndex = (Math.random() * IMAGES.length) | 0
    setSelectedImage(IMAGES[randomIndex])
  }, [])

  return selectedImage ? (
    <Image
      src={`https://res.cloudinary.com/coastline-academy/image/upload/hero-images/${selectedImage}`}
      alt="Coastline Academy"
      width={width}
      height={height}
      priority={true}
      style={style}
    />
  ) : (
    <Skeleton variant="rectangular" width={width} height={height} sx={style} />
  )
}

export default ImageRandomizer
