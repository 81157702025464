import { Box, Typography } from "@mui/material"
import HowToGetLicense from "components/HowToGetLicense"
import i18n from "i18n"

export const btwReviews = [
  {
    text: "I wish I could give more stars. He was on time, very professional, yet with a wonderful sense of humor which made my learning alot fun and easy.",
    name: "Philomena",
    location: "Oakland",
    rating: 5,
  },
  {
    text: "As a mom, I am thrilled that my son is working with a qualified instructor that is teaching him to be a safe confident driver.",
    name: "Catherine",
    location: "Los Gatos",
    rating: 5,
  },
  {
    text: "Very patient and easy to understand. Made my driving experience much easier and less scary knowing I had an amazing teacher to guide me.",
    name: "Hailey",
    location: "Morgan Hill",
    rating: 5,
  },
  {
    text: "My instructor was so easy to drive with and he made me feel so safe and capable!",
    name: "Jahanavi",
    location: "San Francisco",
    rating: 5,
  },
]

export const faqContent = [
  {
    question: "How to Get Your License in each state?",
    answer: (
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          <strong>{i18n.t("Steps to Getting Your License")}</strong>
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          {`${i18n.t(
            "Use this guide to make sure you are in the right place in your licensing journey",
          )}.`}
        </Typography>
        <HowToGetLicense
          controlsToShow={["state", "path"]}
          highlightId="practice-for-written-test"
        />
      </Box>
    ),
  },
  {
    question: "Do you have a driving school near me?",
    answer: (
      <>
        Our team of certified instructors serve 95% of California and many more
        locations in other states. Enter your address here to see if we offer
        {` `}
        <a href="https://coastlineacademy.com/location-search">
          driving lessons near you
        </a>
        .
      </>
    ),
  },
  {
    question: "How long does it take to learn to drive?",
    answer:
      "The short answer is it depends. Each state has different rules and regulations which will affect what age you are allowed to drive, how long you must hold a permit, and when you can take your state road test. You can learn more about these requirements here. On the human side, it can also vary a lot. Some people learn to drive quickly and may have access to many lessons beyond the required amount and are able to practice for longer amounts of time. Others might be less comfortable behind the wheel and will take longer to build their skill set. Whatever your skill or comfort level, Coastline instructors are available to work with you to become a safe and confident driver for life. ",
  },
  {
    question: "How much are driving lessons?",
    answer: (
      <>
        At Coastline we serve customers in hundreds of cities across eights
        states. In each area the available packages and costs are slightly
        different. Our most common package includes three, 2-hour lessons,
        although we encourage families to consider extra time behind the wheel.
        We also offer several “buy now, pay later” options with any package,
        making lessons even more accessible. To see how much driving lessons are
        near you, <a href="https://coastlineacademy.com/pricing">click here!</a>
      </>
    ),
  },
  {
    question: "How to become a driving instructor?",
    answer: (
      <>
        You will need to undergo formal training with Coastline and then be
        licensed by your state driving agency (usually the DMV). We provide
        everything you need to become an instructor and will even pay you as you
        learn! <a href="https://coastline.breezy.hr/">Learn more</a>.
      </>
    ),
  },
]
