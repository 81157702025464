import { Box } from "@mui/material"
import Link from "next/link"
import { Chat, Email, Phone } from "@mui/icons-material"

import IconTextBlock from "./IconTextBlock"
import { phoneNumber, SUPPORT_EMAIL } from "./constants"

const LINK_COLOR = "#056b63"

export const ContactBlock: React.FC = () => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      sx={{ "& a": { color: "secondary.dark" } }}
    >
      <Box sx={{ m: 1, flexGrow: 1 }}>
        <IconTextBlock title="Chat" icon={<Chat />}>
          Text us at{" "}
          <Link style={{ color: LINK_COLOR }} href={`sms:${phoneNumber}`}>
            {phoneNumber}
          </Link>
          .
        </IconTextBlock>
      </Box>
      <Box sx={{ m: 1, flexGrow: 1 }}>
        <IconTextBlock title="Email" icon={<Email />}>
          <Link
            href={`mailto:${SUPPORT_EMAIL}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: LINK_COLOR }}
          >
            {" "}
            {SUPPORT_EMAIL}
          </Link>
        </IconTextBlock>
      </Box>
      <Box sx={{ m: 1, flexGrow: 1 }}>
        <IconTextBlock title="Phone" icon={<Phone />}>
          Call us at{" "}
          <Link style={{ color: LINK_COLOR }} href={`tel:${phoneNumber}`}>
            {phoneNumber}
          </Link>
        </IconTextBlock>
      </Box>
    </Box>
  )
}
