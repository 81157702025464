import Link from "next/link"
import { Box, useTheme, useMediaQuery } from "@mui/material"
import { useTranslation } from "react-i18next"

const BASE_URL =
  "https://res.cloudinary.com/coastline-academy/image/upload/w_200,h_200,c_fill,q_auto/location-images/"

const LOCATIONS = [
  {
    name: "California",
    image: "san-francisco-small_voctwo",
    state: "ca",
  },
  {
    name: "Colorado",
    image: "colorado-small_bisggr",
    state: "co",
  },
  {
    name: "Illinois",
    image: "illinois-small_tjd4pe",
    state: "il",
  },
  {
    name: "Indiana",
    image: "indiana-small_hfh2er",
    state: "in",
  },
  {
    name: "Missouri",
    image: "missouri_fzrhlq.jpg",
    state: "mo",
  },
  {
    name: "Nevada",
    image: "nevada-small_atdehn",
    state: "nv",
  },
  {
    name: "Pennsylvania",
    image: "pennsylvania-state-photo_gkgd3l",
    state: "pa",
  },
  {
    name: "Texas",
    image: "dallas-skyline_y5xeil",
    state: "tx",
  },
]

const TopLocations = (): React.ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const { t } = useTranslation()

  return (
    <>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {LOCATIONS.map((location) => (
          <Link
            href={`/state/${location.state}`}
            key={location.state}
            style={{ textDecoration: "none", cursor: "default" }}
          >
            <Box
              sx={{
                backgroundImage: `url(${BASE_URL}${location.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "50%",
                height: isMobile ? 150 : 200,
                m: 2,
                width: isMobile ? 150 : 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bold",
                "&:hover": {
                  opacity: 0.8,
                  cursor: "pointer",
                },
              }}
            >
              {location.name}
            </Box>
          </Link>
        ))}
      </Box>
      <div style={{ marginTop: 20, textAlign: "center" }}>
        <Link href="/locations" style={{ color: "white" }}>
          {t("Browse All Coastline Locations")}
        </Link>
      </div>
    </>
  )
}

export default TopLocations
