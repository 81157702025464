import React from "react"
import { TextField, Button } from "@mui/material"
import { isValidZipcode } from "utils/zipcode"

type Props = {
  onSubmitCallback: (zipcode: string) => void
}

const ZipcodeForm = ({ onSubmitCallback }: Props): JSX.Element => {
  const [zipcode, setZipcode] = React.useState<string>("")
  const isInvalidZipcode = !!(zipcode && !isValidZipcode(zipcode))

  const submitForm = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    if (!zipcode || isInvalidZipcode) {
      return
    }
    onSubmitCallback(zipcode)
  }

  return (
    <>
      <p>Enter Your Zip Code Below to Get Started</p>
      <form onSubmit={submitForm}>
        <TextField
          label="Zip Code"
          onChange={(e) => {
            setZipcode(e.target.value.trim())
          }}
          style={{ marginTop: 0 }}
          value={zipcode}
          name="zipCode"
        />
        <br />
        {isInvalidZipcode && (
          <p style={{ color: "red", marginBottom: 0 }}>
            Please enter a valid 5 digit zipcode
          </p>
        )}
        <Button
          type="submit"
          variant="contained"
          style={{ marginTop: 15 }}
          disabled={!zipcode || isInvalidZipcode}
        >
          View Packages
        </Button>
      </form>
    </>
  )
}

export default ZipcodeForm
