import React from "react"
import Grid from "@mui/material/Grid"
import Image from "next/image"

interface ImageTextBlockProps {
  image: string | JSX.Element
  caption?: string
  children: React.ReactNode
  hideImageOnMobile?: boolean
}

// Side-by-side on desktop, stacked on mobile.
// Content on the left, photo on the right.
const ImageTextBlock = ({
  image,
  children,
  caption,
  hideImageOnMobile = false,
}: ImageTextBlockProps): JSX.Element => (
  <Grid className="image-text-block" container spacing={5} alignItems="center">
    <Grid item xs={12} sx={{ display: hideImageOnMobile ? "block" : "none" }}>
      {typeof image === "string" ? (
        <Image src={image} alt="Coastline" />
      ) : (
        image
      )}
    </Grid>
    <Grid item xs={12} md={6}>
      {children}
    </Grid>
    <Grid
      item
      md={6}
      style={{
        marginTop: 0,
        textAlign: "right",
        display: hideImageOnMobile ? "none" : "block",
      }}
    >
      {typeof image === "string" ? (
        <Image src={image} alt="Coastline" style={{ borderRadius: 60 }} />
      ) : (
        image
      )}
      {caption && <p className="image-caption">{caption}</p>}
    </Grid>
  </Grid>
)

export default ImageTextBlock
