import { Box } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import Image from "next/image"
import { useTranslation } from "react-i18next"

import arrowIcon from "assets/homepage-arrow.svg"

const BuyNowPayLaterBubble = (): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        m: 1,
        maxWidth: 240,
      }}
      className="driving-lesson-financing-bubble"
    >
      <Box>
        <Image
          src={arrowIcon}
          alt="Arrow"
          style={{
            width: 24,
            height: 24,
            transform: "rotate(90deg)",
          }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.accentThree.main,
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: "0.9em",
          padding: 1,
          pl: 2,
          pr: 2,
          borderRadius: 4,
          marginTop: 1,
        }}
      >
        {t("Financing available")}
      </Box>
    </Box>
  )
}

export default BuyNowPayLaterBubble
