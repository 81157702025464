import Box from "@mui/material/Box"
import Image from "next/image"
import { useTranslation } from "react-i18next"

const PaymentLogos = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      flexWrap="wrap"
      sx={{ mt: 2 }}
    >
      <Image
        src="https://res.cloudinary.com/coastline-academy/image/upload/misc-images/credit-cards_sjk47f"
        alt={t("Credit Cards")}
        width={160}
        height={35}
      />

      <Image
        src="https://res.cloudinary.com/coastline-academy/image/upload/misc-images/guarantee_yorwsn"
        alt={t("Money Back Guarantee")}
        width={80}
        height={80}
      />

      <Image
        src="https://res.cloudinary.com/coastline-academy/image/upload/misc-images/secure-ssl_d7imqj"
        alt={t("SSL Encryption")}
        width={124}
        height={43}
      />

      <Image
        src="https://res.cloudinary.com/coastline-academy/image/upload/misc-images/affirm-logo_q3gut7"
        alt={t("Affirm Financing")}
        width={124}
        height={43}
      />

      <Image
        src="https://res.cloudinary.com/coastline-academy/image/upload/misc-images/klarna-logo_dmqf35"
        alt={t("Klarna Financing")}
        width={180}
        height={100}
      />

      <Image
        src="https://res.cloudinary.com/coastline-academy/image/upload/misc-images/afterpay-logo_wgbla2"
        alt={t("Afterpay Financing")}
        width={200}
        height={70}
      />

      <Image
        src="https://res.cloudinary.com/coastline-academy/image/upload/logos/bbb-a-plus"
        alt={t("Better Business Bureau A+ Rating")}
        width={155}
        height={100}
      />
    </Box>
  )
}

export default PaymentLogos
