import { GQLUsStateId } from "generated/documentTypes"

export const getStateFromZipcode = (zipcode: number): string | null => {
  if (zipcode >= 35000 && zipcode <= 36999) {
    return "al" //  Alabama 1
  }
  if (zipcode >= 99500 && zipcode <= 99999) {
    return "ak" // Alaska 2
  }
  if (zipcode >= 85000 && zipcode <= 86999) {
    return "az" // Arizona 3
  }
  if (zipcode >= 71600 && zipcode <= 72999) {
    return "ar" // Arkansas 4
  }
  if (zipcode >= 90000 && zipcode <= 96699) {
    return "ca" // California 5
  }
  if (zipcode >= 80000 && zipcode <= 81999) {
    return "co" // Colorado 6
  }
  if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    return "ct" // Connecticut 7
  }
  if (zipcode >= 19700 && zipcode <= 19999) {
    return "de" // Deleware 8
  }
  if (zipcode >= 32000 && zipcode <= 34999) {
    return "fl" // Florida 9
  }
  if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    return "ga" // Georgia 10
  }
  if (zipcode >= 96700 && zipcode <= 96999) {
    return "hi" // Hawaii 11
  }
  if (zipcode >= 83200 && zipcode <= 83999) {
    return "id" // Idaho 12
  }
  if (zipcode >= 60000 && zipcode <= 62999) {
    return "il" // Illinois 13
  }
  if (zipcode >= 46000 && zipcode <= 47999) {
    return "in" // Indiana 14
  }
  if (zipcode >= 50000 && zipcode <= 52999) {
    return "ia" // Iowa 15
  }
  if (zipcode >= 66000 && zipcode <= 67999) {
    return "ks" // Kansas 16
  }
  if (zipcode >= 40000 && zipcode <= 42999) {
    return "ky" // Kentucky 17
  }
  if (zipcode >= 70000 && zipcode <= 71599) {
    return "la" // Louisiana 18
  }
  if (zipcode >= 3900 && zipcode <= 4999) {
    return "me" // Maine 19
  }
  if (zipcode >= 20600 && zipcode <= 21999) {
    return "md" // Maryland 20
  }
  if (
    (zipcode >= 1000 && zipcode <= 2799) ||
    zipcode === 5501 ||
    zipcode === 5544
  ) {
    return "ma" // Massachusetts 21
  }
  if (zipcode >= 48000 && zipcode <= 49999) {
    return "mi" // Michigan 22
  }
  if (zipcode >= 55000 && zipcode <= 56899) {
    return "mn" // Minnesota 23
  }
  if (zipcode >= 38600 && zipcode <= 39999) {
    return "ms" // Mississippi 24
  }
  if (zipcode >= 63000 && zipcode <= 65999) {
    return "mo" // Missouri 25
  }
  if (zipcode >= 59000 && zipcode <= 59999) {
    return "mt" // Montana 26
  }
  if (zipcode >= 27000 && zipcode <= 28999) {
    return "nc" // North Carolina 27
  }
  if (zipcode >= 58000 && zipcode <= 58999) {
    return "nd" // North Dakota 28
  }
  if (zipcode >= 68000 && zipcode <= 69999) {
    return "ne" // Nebraska 29
  }
  if (zipcode >= 88900 && zipcode <= 89999) {
    return "nv" // Nevada 30
  }
  if (zipcode >= 3000 && zipcode <= 3899) {
    return "nh" // New Hampshire 31
  }
  if (zipcode >= 7000 && zipcode <= 8999) {
    return "nj" // New Jersey 32
  }
  if (zipcode >= 87000 && zipcode <= 88499) {
    return "nm" // New Mexico 33
  }
  if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode === 6390 ||
    zipcode === 501 ||
    zipcode === 544
  ) {
    return "ny" // New York 34
  }
  if (zipcode >= 43000 && zipcode <= 45999) {
    return "oh" // Ohio 35
  }
  if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    return "ok" // Oklahoma 36
  }
  if (zipcode >= 97000 && zipcode <= 97999) {
    return "or" // Oregon 37
  }
  if (zipcode >= 15000 && zipcode <= 19699) {
    return "pa" // Pennsylvania 38
  }
  if (zipcode >= 300 && zipcode <= 999) {
    return "pr" // Puerto Rico 39
  }
  if (zipcode >= 2800 && zipcode <= 2999) {
    return "ri" // Rhode Island 40
  }
  if (zipcode >= 29000 && zipcode <= 29999) {
    return "sc" // South Carolina 41
  }
  if (zipcode >= 57000 && zipcode <= 57999) {
    return "sd" // South Dakota 42
  }
  if (zipcode >= 37000 && zipcode <= 38599) {
    return "tn" // Tennessee 43
  }
  if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    return "tx" // Texas 44
  }
  if (zipcode >= 84000 && zipcode <= 84999) {
    return "ut" // Utah 45
  }
  if (zipcode >= 5000 && zipcode <= 5999) {
    return "vt" // Vermont 46
  }
  if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode === 20598
  ) {
    return "va" // Virginia 47
  }
  if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    return "dc" // Washington DC 48
  }
  if (zipcode >= 98000 && zipcode <= 99499) {
    return "wa" // Washington 49
  }
  if (zipcode >= 24700 && zipcode <= 26999) {
    return "wv" // West Virginia 50
  }
  if (zipcode >= 53000 && zipcode <= 54999) {
    return "wi" // Wisconsin 51
  }
  if (zipcode >= 82000 && zipcode <= 83199) {
    return "wy" // Wyoming 52
  }

  return null
}

export const isValidZipcode = (zipcode: string): boolean => {
  const regex = /^\d{5}$/
  return regex.test(zipcode)
}

export function getUsStateIdFromZipcode(
  zipcode: number,
): GQLUsStateId | undefined {
  const usStateIdStr = getStateFromZipcode(zipcode)
  if (!usStateIdStr) return undefined
  return usStateIdStr.toUpperCase() as GQLUsStateId
}
